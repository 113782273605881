import Resource from "@/services/Resource";
import { activeField, activeFilter, deleteBatchAction, nameField } from "./common";

export const FEATURE_BATCHES = 1;
export const FEATURE_BATCH_CREDIT_CARDS = 2;
export const FEATURE_BRANDING = 4;
export const FEATURE_CONTENT = 8;
export const FEATURE_MANIFESTS = 16;
export const FEATURE_POSTPAYMENTS = 32;
export const FEATURE_FETCHING = 64;

export const BATCH_FEATURES = (FEATURE_BATCH_CREDIT_CARDS)

export const CHANNEL_EMAIL    = 1;
export const CHANNEL_SMS      = 2;
export const CHANNEL_WHATSAPP = 4;

export default class Merchant extends Resource {
  static name = 'merchant'
  static icon = 'bx-cart'
  static fields = [
    nameField(),
    { 
      key: 'user_count', 
      sortable: true,
      label: 'merchants.user_count',
    },
    activeField(),
  ]
  
  static filters = [
    activeFilter(),
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  static relations = [
    {
      name: 'location_groups',
      resource: 'location_groups'
    },
    {
      name: 'users',
      resource: 'users'
    }
  ]

  static extraRoutes({prefix}) {
    return [
      {
        name: `merchants-preferences`,
        path: `${prefix}/merchants/preferences`,
        component: () => import(`@/pages/merchants/Preferences`),
      }
    ]
  }

  get defaults() {
    return {
      country: 'BS',
      channels: {},
    }
  }

  static get features() {
    return [
      { label: `merchants.feature.batches`, code: FEATURE_BATCHES },
      { label: `merchants.feature.batch-credit-cards`, code: FEATURE_BATCH_CREDIT_CARDS },
      { label: `merchants.feature.branding`, code: FEATURE_BRANDING },
      { label: `merchants.feature.content`, code: FEATURE_CONTENT },
      { label: `merchants.feature.manifests`, code: FEATURE_MANIFESTS },
      { label: `merchants.feature.postpayments`, code: FEATURE_POSTPAYMENTS },
      { label: `merchants.feature.fetching`, code: FEATURE_FETCHING },
    ]
  }

  static get channels() {
    return [
      { label: `merchants.channels.${CHANNEL_EMAIL}`, code: CHANNEL_EMAIL, icon: 'bx-envelope' },
      { label: `merchants.channels.${CHANNEL_SMS}`, code: CHANNEL_SMS, icon: 'bx-message-rounded-dots' },
      { label: `merchants.channels.${CHANNEL_WHATSAPP}`, code: CHANNEL_WHATSAPP, icon: 'bxl-whatsapp' },
    ]
  }

  static get fetchServices() {
    return [
      'aeropost',
    ]
  }

  static get events() {
    return [
      {
        code: 'package-in-transit',
      },
      {
        code: 'package-available',
      },
      {
        code: 'package-available-pudo',
      },
      {
        code: 'package-reminder',
      },
      {
        code: 'package-extension-offer',
      },
      {
        code: 'package-rerouted',
      },
      {
        code: 'package-rerouted-pudo',
      },
      {
        code: 'shipment-awaiting-payment',
        $feature: FEATURE_POSTPAYMENTS,
      },
    ]
  }
}

Resource.registerResource(Merchant)