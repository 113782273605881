import { ROLE_ALL, ROLE_CUSTOMER_SUPPORT, ROLE_DRIVER, ROLE_MERCHANT, ROLE_MERCHANT_ADMIN, ROLE_PUDO, ROLE_SUPERADMIN, ROLE_WAREHOUSE } from "./resources/User"

export default {
  'dashboard': ROLE_ALL,

  'pudo': ROLE_PUDO,
  'warehouse': ROLE_WAREHOUSE,
  'driver': ROLE_DRIVER,

  'lockers': /*ROLE_DRIVER | */ROLE_CUSTOMER_SUPPORT,

  'shipments': ROLE_ALL,
  'shipments-show': ROLE_ALL,
  'shipments-create': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'shipments-edit': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'shipments-import': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'shipments-import-upload': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'shipments-import-preview': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'shipments-import-save': ROLE_SUPERADMIN | ROLE_MERCHANT,

  'packages-show': ROLE_ALL,
  'packages-create': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'packages-edit': ROLE_SUPERADMIN | ROLE_MERCHANT,

  'manifests': ROLE_ALL | ROLE_WAREHOUSE,
  'manifests-show': ROLE_ALL | ROLE_WAREHOUSE,
  'manifests-create': ROLE_SUPERADMIN | ROLE_MERCHANT_ADMIN,
  'manifests-edit': ROLE_SUPERADMIN | ROLE_MERCHANT_ADMIN,

  'locations': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'locations-show': ROLE_SUPERADMIN | ROLE_MERCHANT,
  'locations-create': ROLE_SUPERADMIN | ROLE_MERCHANT_ADMIN,
  'locations-edit': ROLE_SUPERADMIN | ROLE_MERCHANT_ADMIN,

  'batches': ROLE_ALL,
  'batches-show': ROLE_ALL,
  'batches-create': ROLE_ALL,
  'batches-edit': ROLE_ALL,

  'batch_items-show': ROLE_ALL,
  // 'batch_items-create': ROLE_ALL,
  'batch_items-edit': ROLE_ALL,

  'location_groups': ROLE_SUPERADMIN,
  'location_groups-show': ROLE_SUPERADMIN,
  'location_groups-create': ROLE_SUPERADMIN,
  'location_groups-edit': ROLE_SUPERADMIN,

  'merchants': ROLE_SUPERADMIN,
  'merchants-show': ROLE_SUPERADMIN,
  'merchants-create': ROLE_SUPERADMIN,
  'merchants-edit': ROLE_SUPERADMIN,
  'merchants-preferences': ROLE_MERCHANT_ADMIN,

  'users': ROLE_SUPERADMIN | ROLE_MERCHANT_ADMIN,
  'users-show': ROLE_ALL | ROLE_PUDO | ROLE_WAREHOUSE | ROLE_DRIVER,
  'users-create': ROLE_SUPERADMIN | ROLE_MERCHANT_ADMIN,
  'users-edit': ROLE_ALL | ROLE_PUDO | ROLE_WAREHOUSE | ROLE_DRIVER,
  'users-profile': ROLE_ALL,
}